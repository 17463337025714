import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
  },
}));

function PrivacyPolicy(props) {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const arr = [
    'privacy_policy_1A',
    'privacy_policy_1B',
    'privacy_policy_1C',
    'privacy_policy_1D',
    'privacy_policy_2A',
    'privacy_policy_2B',
    'privacy_policy_2C',
    'privacy_policy_2D',
    'privacy_policy_2E',
    'privacy_policy_3A',
    'privacy_policy_3B',
    'privacy_policy_4A',
    'privacy_policy_4B',
    'privacy_policy_4C',
    'privacy_policy_4D',
    'privacy_policy_4E',
    'privacy_policy_4F',
    'privacy_policy_4G',
    'privacy_policy_4H',
    'privacy_policy_5A',
    'privacy_policy_5B',
    'privacy_policy_5C',
    'privacy_policy_5D',
    'privacy_policy_5E',
    'privacy_policy_5F',
    'privacy_policy_5G',
    'privacy_policy_5H',
    'privacy_policy_5I',
    'privacy_policy_5J',
    'privacy_policy_5K',
    'privacy_policy_5L',
    'privacy_policy_5M',
    'privacy_policy_5N',
    'privacy_policy_5O',
    'privacy_policy_5P',
    'privacy_policy_5Q',
    'privacy_policy_5R',
    'privacy_policy_5S',
    'privacy_policy_5T',
    'privacy_policy_5U',
    'privacy_policy_6A',
    'privacy_policy_6B',
    'privacy_policy_7A',
    'privacy_policy_7B',
    'privacy_policy_8A',
    'privacy_policy_8B',
    'privacy_policy_8C',
    'privacy_policy_8D',
    'privacy_policy_8E',
    'privacy_policy_8F',
    'privacy_policy_8G',
    'privacy_policy_8H',
    'privacy_policy_8I',
    'privacy_policy_8J',
    'privacy_policy_8K',
    'privacy_policy_8L',
    'privacy_policy_8M',
    'privacy_policy_8N',
    'privacy_policy_8O',
    'privacy_policy_8P',
    'privacy_policy_8Q',
    'privacy_policy_8R',
    'privacy_policy_8S',
    'privacy_policy_9A',
    'privacy_policy_9B',
    'privacy_policy_10A',
    'privacy_policy_10B',
    'privacy_policy_10_1A',
    'privacy_policy_10_1B',
    'privacy_policy_10_2A',
    'privacy_policy_10_2B',
    'privacy_policy_10_2C',
    'privacy_policy_10_2D',
    'privacy_policy_11A',
    'privacy_policy_11B',
    'privacy_policy_12A',
    'privacy_policy_12B',
    'privacy_policy_13A',
    'privacy_policy_13_1A',
    'privacy_policy_13_1B',
    'privacy_policy_13_2A',
    'privacy_policy_13_2B',
    'privacy_policy_13_2C',
    'privacy_policy_13_3A',
    'privacy_policy_13_3B',
    'privacy_policy_13_4A',
    'privacy_policy_13_4B',
    'privacy_policy_13_5A',
    'privacy_policy_13_5B',
    'privacy_policy_14A',
    'privacy_policy_14B',
    'privacy_policy_15A',
    'privacy_policy_15B',
  ];

  return (
    <div>
      <Typography
        className={classes.title}
        variant="h5"
        id="info_privacy"
        gutterBottom
      >
        {t('info.privacy_policy')}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("info.privacy_policy_explanation")}
      </Typography>
      {arr.map((item, index) =>
        item.includes('A') ? (
          <Typography key={index} gutterBottom variant="h6">
            {t(`info.${item}`)}
          </Typography>
        ) : (
          <Typography key={index} gutterBottom variant="body2">
            {t(`info.${item}`)}
          </Typography>
        ),
      )}
    </div>
  );
}

export default PrivacyPolicy;
