import React from 'react';
import ReactCountryFlag from "react-country-flag"
import Button from 'components/Button/Button';

function Flag(props) {
    return (
        <Button
            className={props.buttonClass}
            onClick={() => props.changeLang(props.lang)}
        >
            <ReactCountryFlag countryCode={props.code} svg title={props.code} style={{
                width: '1.2em',
                height: '1.2em',
            }} />
        </Button>


    );
}

export default Flag;