import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/Button/Button';
import TextInput from 'components/TextInput/TextInput';

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
  textField: {
    height: 60,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    minHeight: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  },
});

function ForgotPassword(props) {
  const { classes, error } = props;
  const { t } = useTranslation('common');

  return (
    <form noValidate autoComplete="on" className={classes.form}>
      <Typography variant="h5" className={classes.header}>
        {t('password.forgot_password')}
      </Typography>
      <Typography variant="body1" className={classes.error}>
        {error ? error : ''}
      </Typography>
      <TextInput
        value={props.username}
        onChange={(v, c, e, fv) => props.dataChanged('username')(fv)}
        type='phone'
        required
        fullWidth={true}
        data-testid="username-id"
        label={t('login.username')}
      />
      <Button
        className={classes.button}
        fullWidth
        onClick={props.recoverPassword}
        variant="contained" color="primary"
      >
        {t('password.send_recovery_code')}
      </Button>
    </form>
  )
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  dataChanged: PropTypes.func.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  username: PropTypes.string,
};

export default withStyles(styles)(ForgotPassword);