import React, { Component } from "react";
import { connect } from "react-redux";
import { ROUTE } from "../../store/actions/router";
import PropTypes from "prop-types";

import Intro from "../../scenes/Intro/Intro";
import Login from "../../scenes/Login/Login";
import Registration from "../../scenes/Registration/Registration";
import RegistrationResident from "../../scenes/RegistrationResident/Registration";
import ResetPassword from "../../scenes/ResetPassword/ResetPassword";
import Verification from "../../scenes/Verification/Verification";
import Logout from "../../scenes/Logout/Logout";
import ResidentIntro from "scenes/ResidentIntro/ResidentIntro";

class Router extends Component {
  render = () => {
    let view = <Intro />;

    if (new URL(window.location.href).pathname === "/api/logout/") {
      view = <Logout />;
    } else if (this.props.route === ROUTE.LOGIN) {
      view = <Login />;
    } else if (this.props.route === ROUTE.REGISTRATION) {
      view = <Registration />;
    } else if (this.props.route === ROUTE.REGISTRATIONRESIDENT) {
      view = <RegistrationResident />;
    } else if (this.props.route === ROUTE.RESET_PASSWORD) {
      view = <ResetPassword />;
    } else if (this.props.route === ROUTE.VERIFICATION) {
      view = <Verification />;
    } else if (
      new URL(window.location.href).pathname === "/info" ||
      new URL(window.location.href).pathname === "/info/"
    ) {
      view = <ResidentIntro />;
    } else if (this.props.route === ROUTE.HOME) {
      view = <Intro />;
    } else if (this.props.route === ROUTE.RESIDENTHOME) {
      view = <ResidentIntro />;
    }

    return view;
  };
}

Router.propTypes = {
  route: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    route: state.router.route,
  };
};

export default connect(mapStateToProps)(Router);
