import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import DialogHeader from 'components/DialogHeader/DialogHeader';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		...theme.component.dialogPaper,
    border: theme.component.border,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 24,
			marginRight: 24,
		}
	}
}));

function TermsDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('common');
	const { type } = props;

	return (
		<Dialog
			aria-labelledby="terms-service-dialog"
			classes={{ paper: classes.dialogPaper }}
			fullWidth
			maxWidth='md'
			open={props.open}
			onClose={props.onClose}
		>
			<DialogHeader
				title={t('info.privacy_policy')}
				onClose={props.onClose}
			/>
			<DialogContent className={classes.dialogContent}>
				{type === 'privacy' ? <PrivacyPolicy /> : <TermsOfService />}
			</DialogContent>
		</Dialog>
	)
}

TermsDialog.defaultProps = {
	onClose: () => {},
}

TermsDialog.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
}

export default TermsDialog