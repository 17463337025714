import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Button/Button';

import { LogoNegaPng } from 'lixani-lib-data';
import { LayoutContent } from 'lixani-lib-ui';

const toolbarMinHeight = 48;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
		width: '100%',
		zIndex: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	toolbar: {
		backgroundColor: '#1687af',
		justifyContent: 'space-between',
		minHeight: toolbarMinHeight,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(0.5),
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(2.5),
			paddingRight: theme.spacing(1),
		},
	},
	logoButton: {
		padding: 0,
	},
	logo: {
		height: 25,
	},
	logoContainer: {
		flex: 1,
	},
}));

function Layout(props) {
	const { children } = props;
	const classes = useStyles();
	const { t } = useTranslation('common');

	return (
		<div className={classes.root}>
			<AppBar elevation={0} className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<div className={classes.logoContainer}>
						<Button
							className={classes.logoButton}
							onClick={props.routeHome}
						>
							<img
								className={classes.logo}
								alt={'Lixani logo'}
								src={`data:image/png;base64,${LogoNegaPng}`}
							/>
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			<LayoutContent
				marginTop={48}
				footerText={t('footer.powered_by')}
				layoutStyle={{ backgroundColor: 'initial' }}
				bgStyle={{
					minHeight: `calc(100vh - ${48}px)`
				}}
			>
				{/*<div style={{"maxWidth":1024,"margin":"auto"}}>*/}
				{children}
				{/*</div>*/}
			</LayoutContent>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.array.isRequired,
	routeHome: PropTypes.func,
};

export default Layout;