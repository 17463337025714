import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import LayoutSimpleGrid from 'components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';
import Navigate from './components/Navigate/Navigate';
import ProductIntro from './components/ProductIntro/ProductIntro';

import { setRoute, ROUTE } from '../../store/actions/router';

const styles = theme => ({
  navigate: {
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  productIntro: {
    marginTop: '10vh',
    width: '100%',
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  langSelect: {
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
});

class Intro extends Component {
  render() {
    const { classes, setLoginView, setRegisterView, setRegisterResidentView } = this.props;
    return (
      <LayoutSimpleGrid>
        <Grid className={classes.navigate}>
          <Navigate
            login={setLoginView}
            register={setRegisterView}
            registerResident={setRegisterResidentView} />
        </Grid>
        <Grid className={classes.langSelect}>
          <LanguageSelect />
        </Grid>
        <Grid className={classes.productIntro}>
          <ProductIntro />
        </Grid>
      </LayoutSimpleGrid>
    )
  }
}

Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  setLoginView: PropTypes.func.isRequired,
  setRegisterView: PropTypes.func.isRequired,
  setRegisterResidentView: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    setLoginView: () => dispatch(setRoute(ROUTE.LOGIN)),
    setRegisterView: () => dispatch(setRoute(ROUTE.REGISTRATION)),
    setRegisterResidentView: () => dispatch(setRoute(ROUTE.REGISTRATIONRESIDENT))
  }
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Intro));
