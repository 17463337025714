import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Flag from 'components/Flag/Flag';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
	},
	button: {
		fontSize: '2rem',
		lineHeight: 1,
	}
}));

function LanguageSelect() {
	const classes = useStyles();
	const { i18n } = useTranslation();

	const handleChangeLanguage = lang => {
		i18n.changeLanguage(lang);
	}

	return (
		<div className={classes.root}>
			<Flag code="FI" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='fi'/>
			<Flag code="GB" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='en' />
			<Flag code="EE" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='et' />
			<Flag code="SE" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='sv' />
			<Flag code="PL" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='pl' />
			<Flag code="RO" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='ro' />
			<Flag code="RU" buttonClass={classes.button} changeLang={handleChangeLanguage} lang='ru' />
		</div>
	)
}

export default LanguageSelect
