import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRoute, ROUTE } from '../../store/actions/router';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import LayoutSimpleGrid from '../../components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';
import RegistrationNavigate from './components/RegistrationNavigate/RegistrationNavigate';
import RegistrationLogic from './RegistrationLogic';

const styles = theme => ({
  registration: {
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  navigate: {
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
});

class Registration extends Component {
  render() {
    const { classes, setHomeView } = this.props;
    return (
      <LayoutSimpleGrid>
        <Grid className={classes.registration}>
          <RegistrationLogic />
        </Grid>
        <Grid className={classes.navigate}>
          <RegistrationNavigate
            home={setHomeView}
          />
        </Grid>
      </LayoutSimpleGrid>
    )
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  setHomeView: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setHomeView: () => dispatch(setRoute(ROUTE.HOME)),
  }
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Registration));