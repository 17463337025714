import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/Button/Button';
import TextInput from 'components/TextInput/TextInput';

const styles = theme => ({
  textField: {
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  error: {
    minHeight: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  },
  info: {
    height: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
});

function ConfirmPassword(props) {
  const { t } = useTranslation('common');
  const {
    classes,
    error,
    verificationData,
    dataChanged,
    passwordError,
    passwordHelper,
    confirmRecovery,
    confirmEnabled,
  } = props;

  return (
    <form noValidate autoComplete="new-password" className={classes.form}>
      <Typography variant="h5" className={classes.headline}>
        {t('password.set_password')}
      </Typography>
      <Typography variant="body1" className={error ? classes.error : classes.info}>
        {error ? error :
          t('password.confirmation_destination', { destination: verificationData.destination })}
      </Typography>
      <TextInput
        onChange={dataChanged('verificationCode')}
        required
        fullWidth={true}
        margin="normal"
        label={t('password.confirmation_code')} />
      <TextInput
        onChange={dataChanged('password')}
        required
        label={t('auth.password')}
        type="password"
        fullWidth={true}
        error={passwordError}
        helpertext={passwordHelper(props.password)}
        margin="normal" />
      <Typography variant="caption">
        {t('auth.password_requirements')}
      </Typography>
      <Button
        className={classes.button}
        disabled={!confirmEnabled}
        fullWidth
        onClick={confirmRecovery}
        variant="contained" color="primary"
      >
        {t('password.set_password')}
      </Button>
    </form>
  )
}

ConfirmPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  dataChanged: PropTypes.func.isRequired,
  confirmRecovery: PropTypes.func.isRequired,
  confirmEnabled: PropTypes.bool.isRequired,
  verificationData: PropTypes.object.isRequired,
  passwordError: PropTypes.bool.isRequired,
  passwordHelper: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default withStyles(styles)(ConfirmPassword);