import React, { useState, useEffect } from 'react';
import Config from '../../Config';
import PropTypes from 'prop-types';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';

import RegistrationForm from './components/RegistrationForm/RegistrationForm';

import { setEmail, setPhone, setIsPhoneAccount } from '../../store/actions/user';
import { setRoute, ROUTE } from '../../store/actions/router';

import { passwordHelper } from 'util/password';
import { password2Helper } from 'util/password2';

const initRegistrationData = {
	project_key: { value:'', required: true, error: false},
	city: { value:'', required: true, error: false },
	country: { value:'', required: true, error: false },
  email: { value: '', required: true, error: false, type: 'email', },
  first_name: { value: '', required: true, error: false, },
  last_name: { value: '', required: true, error: false, },
  password: { value: '', required: true, error: false, type: 'password' , check_same:"password2" },
  password2: { value: '', required: true, error: false, type: 'password', is_same:"password" },
  phone: { value: '', required: true, error: false, type: 'phone', },
	postcode: { value:'', required: true, error: false },
	prefill: {value:false,required: false, error: false},
	street_address: { value:'', required: true, error: false },
  info: { value: '', required: false, error: false }
}

function RegistrationLogic(props) {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stateError, setError] = useState(null);
  const [registered, setRegistered] = useState(false);
  const [registrationData, setRegistrationData] = useState({ ...initRegistrationData });
  const [userConfirmed, setUserConfirmed] = useState(false);
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    const initPhone = () => {
      let prefill = "";
      
      // callback if not signed in.
      try {
        prefill = decodeURIComponent(new URL(window.location.href).search.split("?callback=")).split("?data=");
      } catch(err) {
        prefill = new URL(window.location.href).search.split("?data=");
      }
      
      let prefilldata = {}
      if (prefill.length > 1) {
        try {
          let d = JSON.parse(atob(prefill[1]));
          prefilldata = Object.keys(d).reduce((resp,key)=>{
            resp[key] = {...initRegistrationData[key],value:d[key]};
            return resp;
          },{});
          prefilldata["prefill"] = {value:d.prefill?d.prefill.value:true,required:false,error:false};
        } catch(err) {
          console.log(err);
        }
      }
     
      if (!props.phone && prefilldata["phone"]) {
        props.onSetPhone(prefilldata["phone"].value)
      }

      return prefilldata['phone'] || '';
    }
 
    initPhone();
    }, [])

  const register = async () => {
    setError(null);
    setLoading(true);
    let userPool = new CognitoUserPool({
      UserPoolId: Config.COGNITO_USER_POOL_ID,
      ClientId: Config.COGNITO_CLIENT_ID
    });

    const data = { ...registrationData };
    const email = props.email.toLowerCase();
    const phone = props.phone.replace(/[^a-zA-Z0-9+ ]/g, '');
    const username = props.isPhoneAccount ? phone : email;

    let attributeList = [];
    attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: email }));
    attributeList.push(new CognitoUserAttribute({ Name: 'phone_number', Value: data.phone.value.replace(/[^a-zA-Z0-9+ ]/g, '') }));
    attributeList.push(new CognitoUserAttribute({ Name: 'given_name', Value: data.first_name.value }));
    attributeList.push(new CognitoUserAttribute({ Name: 'family_name', Value: data.last_name.value }));
		
		const reddata = Object.keys(data).filter((key)=>!(["password","password2","email","phone","first_name","last_name"].includes(key))).reduce((resp,key)=>{
			const ob=data[key];
			if(ob.value) {
				resp[key]=ob.value;
			}
			return resp;
    },{});
    
      
    attributeList.push(new CognitoUserAttribute({ Name: 'custom:employee_base64', Value: btoa(JSON.stringify(reddata)) }));

    //-- Add locale attribute
    let locale = i18n.language;
    if (!locale) {
      locale = navigator.language || navigator.userLanguage;
    }
    locale = locale.split('-')[0];
    attributeList.push(new CognitoUserAttribute({ Name: 'locale', Value: locale }));

    console.log("CREATING USER WITH THIS DATA", data)
    console.log("ATTRIBUTELIST", attributeList)
    console.log("REDDATA", reddata)
    

    let result = null;
    try {
      result = await new Promise((resolve, reject) => {
        userPool.signUp(username, data.password.value, attributeList, null, (error, result) => {
          if (error) reject(error);
          else resolve(result);
        });
      });
    } catch (error) {
      setLoading(false);
      console.error("Registration error", JSON.stringify(error));
      if (error.code === 'UsernameExistsException') {
        setError(props.t("error.already_registered"));
      } else if (error.message === "Invalid phone number format.") {
        setError(props.t("error.invalid_phone_format"));
      } else {
        setError(error.message);
      }
      return;
    }

    setCognitoUser(result.user);
    setRegistered(true);
    setRegistrationData({ ...initRegistrationData });
    setUserConfirmed(result.userConfirmed);
  };

  const handleChangeData = name => event => {
    let data = { ...registrationData[name] }
		let value = event;
    try {
      value = event.target.value;
    } catch (err) {
      //console.log(err);
    }

    if (name === "phone") {
      value = value.replace(/ /g,"");
    }
		data.value = value;

    if (data.type === 'email') {
      props.onSetEmail(value); //event.target.value);
    } else if (data.type === 'phone') {
      props.onSetPhone(value); //event.target.value);
    }		

    data.error = !dataValid(data)(true);

    setRegistrationData({
      ...registrationData,
      [name]: data,
    })
  };

  const dataValid = (data) => (check) => {
    let value = data.value;
    if (data.type === 'date') {
      const dateValue = new Date(value);
      let compareDate = new Date();
      const currentYear = compareDate.getFullYear();
      compareDate.setFullYear(currentYear - 13);
      compareDate.setMonth(-1);
      compareDate.setDate(-1);

      if (dateValue > compareDate) {
        return false;
      }
    }

		if (data.required && value.trim() === '') {
      return false;
    }

		if (data.is_same) {
      if (password2Helper(props.t)(registrationData[data.is_same].value,value) !== null)
        return false;
		} else {
      if (data.type === 'password' && passwordHelper(props.t)(value) !== null) {
        return false;
			}
		}
    return true;
  }





  const togglePhoneAccount = () => {
    const isPhoneAccount = !props.isPhoneAccount;
    setRegistrationData({
      ...registrationData,
      email: { ...registrationData.email, required: !isPhoneAccount },
      phone: { ...registrationData.phone, required: isPhoneAccount },
    });

    props.onSetIsPhoneAccount(isPhoneAccount);
  }

  let content = null;
  let formValid = Object.entries(registrationData).every(d => dataValid(d[1])(false))

  if (!registered) {
    content = (
      <RegistrationForm
        data={registrationData}
        email={props.email}
        error={stateError}
        isPhoneAccount={props.isPhoneAccount}
        isValid={dataValid(true)}
        loading={loading}
        phone={props.phone}
        onChangeData={handleChangeData}
        onChangeEmail={props.onSetEmail}
        onChangePhone={props.onSetPhone}
        passwordHelper={passwordHelper(props.t)}
        password2Helper={password2Helper(props.t)}
        register={register}
        submitEnabled={formValid}
        togglePhoneAccount={togglePhoneAccount}
      />
    )
  } else if (userConfirmed) {
    content = <h3>Registration successful, {cognitoUser.getUsername()}</h3>
  } else {
    props.setVerificationView();
  }

  return content;
}

RegistrationLogic.propTypes = {
  t: PropTypes.func.isRequired,
  setVerificationView: PropTypes.func.isRequired,
  onSetEmail: PropTypes.func.isRequired,
  onSetPhone: PropTypes.func.isRequired,
  onSetIsPhoneAccount: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  isPhoneAccount: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    setVerificationView: PropTypes.func.isRequired,
    email: state.user.email,
    phone: state.user.phone,
    isPhoneAccount: state.user.isPhoneAccount,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setVerificationView: () => dispatch(setRoute(ROUTE.VERIFICATION)),
    onSetEmail: (email) => dispatch(setEmail(email)),
    onSetPhone: (phone) => dispatch(setPhone(phone)),
    onSetIsPhoneAccount: (isPhoneAccount) => dispatch(setIsPhoneAccount(isPhoneAccount)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    RegistrationLogic));
export { RegistrationLogic };