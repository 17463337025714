import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'flex',
	},
}));

function TermsOfService() {
	const classes = useStyles();
	const { t } = useTranslation('common');

	return(
		<div>
			<Typography className={classes.title} variant="h5" id="info_tos" gutterBottom>
				{t('info.terms_of_service')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_A01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_A02')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_B01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_B02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_B03')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_C01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C03')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C04')}
			</Typography>
			<Typography variant="body2">
				{t('info.terms_of_service_C05')}
			</Typography>
			<Typography variant="body2">
				{t('info.terms_of_service_C06')}
			</Typography>
			<Typography variant="body2">
				{t('info.terms_of_service_C07')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C08')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C09')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C10')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_C11')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_D01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_D02')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_E01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_E02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_E03')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_E04')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_F01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_F02')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_G01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_G02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_G03')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_G04')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_H01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_H02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_H03')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_I01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_I02')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_J01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_J02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_J03')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_J04')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_J05')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_K01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_K02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_K03')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_K04')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_L01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_L02')}
			</Typography>
			<Typography gutterBottom variant="h6">
				{t('info.terms_of_service_M01')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_M02')}
			</Typography>
			<Typography gutterBottom variant="body2">
				{t('info.terms_of_service_M03')}
			</Typography>
		</div>
	)
}

export default TermsOfService;