import * as actionTypes from './actionTypes';

export const ROUTE = Object.freeze({
  HOME: "HOME",
  RESIDENTHOME: "RESIDENTHOME",
  LOGIN: "LOGIN",
  REGISTRATION: "REGISTRATION",
  REGISTRATIONRESIDENT: "REGISTRATION_RESIDENT",
  RESET_PASSWORD: "RESET_PASSWORD",
  VERIFICATION: "VERIFICATION",
});

export const setRoute = ( value ) => {
    return {
        type: actionTypes.SET_ROUTE,
        val: value
    };
};