import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonBase from '@material-ui/core/ButtonBase';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography/Typography';

import { withStyles } from '@material-ui/core/styles';

import OpenInNewIcon from 'mdi-material-ui/OpenInNew';

import Button from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import TermsDialog from 'components/TermsDialog/TermsDialog';
import TextInput from 'components/TextInput/TextInput';


const styles = theme => ({
  textField: {
    height: 45,
  },
	box: {
		width: "100%",
		//marginTop: 20,
		//paddingBottom: 20,
		textAlign: "center",
	},
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  employerButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  headline: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
  error: {
    minHeight: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  termsButton: {
    background: 'rgba(20, 123, 164, 0.5)',
    marginBottom: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
		transition: theme.transitions.create(['background']),
    '&:hover': {
      background: 'rgba(20, 123, 164, 0.75)',
    }
  },
  termsButtonIcon: {
    fontSize: 'inherit',
    marginLeft: theme.spacing(1),
  },
  hr: {
    borderColor: 'rgba(255,255,255,0.5)',
    borderTop: 'none',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
  },
});

function RegistrationForm(props) {
  const { t } = useTranslation('common');
  const {
    classes, data, passwordHelper, password2Helper, error,
    loading, register, submitEnabled
  } = props;

  const [termsAccepted, setTermsAccepted] = useState(false);
	const [termsDialogOpen, setTermsDialogOpen] = useState(false);
	const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
	const sender = data.sender ? data.sender.value : null;

  /**
   * Login when pressing enter.
   * @param {*} event 
   */
  function handleKeyPress(event) {
    if (event.charCode === 13 && submitEnabled) {
      event.preventDefault();
      event.stopPropagation();
      register();
    }
  }

  const renderInputs = (prefill) => (input) => {
    const RenderInput = (
      <TextInput
        fullWidth={true}
        key={input[0]}
        label={input[1]}
        margin="dense"
        onChange={props.onChangeData(input[0])}
        readOnly={prefill}
        value={data[input[0]].value}
      />
    )
    return RenderInput;
  } 

	return (
    <form noValidate autoComplete="off" className={classes.form}>
      <Typography variant="h5" className={classes.headline}>
        {t('auth.register_info_resident')}
        {sender && <div>
          <br/>
          Kutsuja: {sender.first_name} {sender.last_name}<br/>
          {sender.phone}<br/>
          {sender.email}
        </div>}
      </Typography>
      
      <Typography variant="body1" className={classes.error}>
        {error ? error : ''}
      </Typography>

      <TextInput value={data.project_key.value}
        onChange={props.onChangeData('project_key')}
        required={data.project_key.required}
        fullWidth={true}
        error={data.project_key.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.project_key')}
      />
      
      <TextInput value={data.first_name.value}
        onChange={props.onChangeData('first_name')}
        required={data.first_name.required}
        fullWidth={true}
        error={data.first_name.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.first_name')}
      />

      <TextInput value={data.last_name.value}
        onChange={props.onChangeData('last_name')}
        required={data.last_name.required}
        fullWidth={true}
        error={data.last_name.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.last_name')}
      />

      <TextInput value={data.email.value}
        onChange={props.onChangeData('email')}
        required={data.email.required}
        fullWidth={true}
        error={data.email.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.email')}
      />

      <TextInput value={data.street_address.value}
        onChange={props.onChangeData('street_address')}
        required={data.street_address.required}
        fullWidth={true}
        error={data.street_address.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('contact.street_address')}
      />

      <TextInput value={data.postcode.value}
        onChange={props.onChangeData('postcode')}
        required={data.postcode.required}
        fullWidth={true}
        error={data.postcode.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('contact.postcode')}
      />

      <TextInput value={data.city.value}
        onChange={props.onChangeData('city')}
        required={data.city.required}
        fullWidth={true}
        error={data.city.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('contact.city')}
      />

      <TextInput value={data.country.value}
        onChange={props.onChangeData('country')}
        required={data.country.required}
        fullWidth={true}
        error={data.country.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('contact.country')}
      />

      <TextInput value={data.info.value}
        onChange={props.onChangeData('info')}
        required={data.info.required}
        fullWidth={true}
        error={data.info.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.info')}
        multiline
      />

      <TextInput
        error={data.phone.error}
        fullWidth
        label={t('auth.phone_number')}
        margin="dense"
        name="username"
        onChange={(v,c,e,fv) => props.onChangeData('phone')(fv)}
        onKeyPress={handleKeyPress}
        required={data.phone.required}
        type="phone"
        value={data.phone.value}
      />

      <TextInput value={data.password.value}
        onChange={props.onChangeData('password')}
        required={data.password.required}
        error={data.password.error}
        label={t('auth.password')}
        type="password"
        fullWidth={true}
        helpertext={passwordHelper(data.password.value,data.password.value)}
        onKeyPress={handleKeyPress}
        margin="dense"
      />

      <Typography variant="caption">
        {t('auth.password_requirements')}
      </Typography>
      
      <TextInput value={data.password2.value}
        onChange={props.onChangeData('password2')}  
        required={data.password2.required}
        error={!props.isValid(data.password2)}
        label={t('auth.password2')}
        type="password"
        fullWidth={true}
        helpertext={password2Helper(data.password.value,data.password2.value)}
        onKeyPress={handleKeyPress}
        margin="dense"
      />

      <div className={classes.termsContainer}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                name="termsAccepted"
                color="primary"
              />
            }
            label={t('info.i_accept_terms')}
          />
        </FormGroup>
        {/* <ButtonBase
          className={classes.termsButton}
          onClick={() => setTermsDialogOpen(true)}
        >
          {t('info.terms_of_service')} <OpenInNewIcon className={classes.termsButtonIcon} />
        </ButtonBase> */}
        <ButtonBase
          className={classes.termsButton}
          onClick={() => setPrivacyDialogOpen(true)}
        >
          {t('info.privacy_policy')} <OpenInNewIcon className={classes.termsButtonIcon} />
        </ButtonBase>
      </div>

      <Button
        className={classes.button}
        disabled={(!submitEnabled || !termsAccepted)}
        fullWidth
        onClick={register}
        variant="contained" color="primary"
      >
        {t('auth.register')}
        {loading && <Loading className={classes.loading} />}
      </Button>

      <TermsDialog
        type={"privacy"}
        open={privacyDialogOpen}
        onClose={() => {setPrivacyDialogOpen(false)}}
      />

    </form>
  )
}

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  isPhoneAccount: PropTypes.bool.isRequired,
	isValid:PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onChangeData: PropTypes.func.isRequired,
  passwordHelper: PropTypes.func.isRequired,
  password2Helper: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool.isRequired,
  togglePhoneAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegistrationForm)
