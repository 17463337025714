import React, { useEffect, useState } from 'react';
import i18n from './i18n'
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next'

import App from './scenes/App';
import './index.css';

import userReducer from './store/reducers/user';
import routerReducer from './store/reducers/router';
import notificationReducer from './store/reducers/notification';



function AppInit(props) {
  const [isInit, setInit] = useState(false);
  
  useEffect(() => {
    i18n.on('initialized', () => {
      setInit(true);
    })
  }, []);

  return (
    isInit && <App />
  )
}

const rootReducer = combineReducers({
  user: userReducer,
  router: routerReducer,
  notification: notificationReducer,
});

const logger = store => {
  return next => {
    return action => {
      //console.log('[Middleware] Dispatching', action);
      const result = next(action);
      //console.log('[Middleware] next state', store.getState());
      return result;
    }
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger)));

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <AppInit />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'));
registerServiceWorker();

// Hide the "Add to Home screen" prompt in account.lixani
const addButton = document.querySelector('.add-button');
if (addButton) addButton.style.display = 'none';

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
})