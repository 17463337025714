const TAXNUMBER_STATUS = Object.freeze({
  UNCHECKED: 'UNCHECKED', // If taxnumber service is down
  INVALID: 'INVALID',			// If taxnumber is invalid by taxnumber check service
  VALID: 'VALID',					// If taxnumber is valid by taxnumber check service
});

const BUSINESS_TYPE = Object.freeze({
	CUSTOMER: 'CUSTOMER',
	EMPLOYER: 'EMPLOYER',
	OWNER: 'OWNER',
	RENTAL_EMPLOYER: 'RENTAL_EMPLOYER',
	SUBCONTRACTOR: 'SUBCONTRACTOR',
	NORMAL: 'NORMAL',
	FOLDER: 'FOLDER',
})

export { BUSINESS_TYPE, TAXNUMBER_STATUS };