import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonBase from '@material-ui/core/ButtonBase';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { withStyles } from '@material-ui/core/styles';

import DownIcon from 'mdi-material-ui/ChevronDown';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';
import RightIcon from 'mdi-material-ui/ChevronRight';

import Button from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import TermsDialog from 'components/TermsDialog/TermsDialog';
import TextInput from 'components/TextInput/TextInput';


const styles = theme => ({
  textField: {
    height: 45,
  },
	box: {
		width: "100%",
		//marginTop: 20,
		//paddingBottom: 20,
		textAlign: "center",
	},
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  employerButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  headline: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
  error: {
    minHeight: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  termsButton: {
    background: 'rgba(20, 123, 164, 0.5)',
    marginBottom: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
		transition: theme.transitions.create(['background']),
    '&:hover': {
      background: 'rgba(20, 123, 164, 0.75)',
    }
  },
  termsButtonIcon: {
    fontSize: 'inherit',
    marginLeft: theme.spacing(1),
  },
  hr: {
    borderColor: 'rgba(255,255,255,0.5)',
    borderTop: 'none',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
  },
});

function RegistrationForm(props) {
  const { t } = useTranslation('common');
  const {
    classes, data, passwordHelper, password2Helper, error,
    loading, register, submitEnabled, businessIdCountry, handleBusinessIdCountryRadioChange,handleBossCheckbox,
  } = props;

	const companyInfoOk = data.name.value === "" || data.business_id.value === "";
  const [eBtn, eBtnSet] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
	const [termsDialogOpen, setTermsDialogOpen] = useState(false);
	const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
	const showCompany = eBtn || false;
	const sender = data.sender ? data.sender.value : null;

  /**
   * Login when pressing enter.
   * @param {*} event 
   */
  function handleKeyPress(event) {
    if (event.charCode === 13 && submitEnabled) {
      event.preventDefault();
      event.stopPropagation();
      register();
    }
  }

  const renderInputs = (prefill) => (input) => {
    const RenderInput = (
      <TextInput
        fullWidth={true}
        key={input[0]}
        label={input[1]}
        margin="dense"
        onChange={props.onChangeData(input[0])}
        readOnly={prefill}
        value={data[input[0]].value}
      />
    )
    return RenderInput;
  } 

	return (
    <form noValidate autoComplete="off" className={classes.form}>
      <Typography variant="h5" className={classes.headline}>
        {t('auth.register_user')}
        {sender && <div>
          <br/>
          Kutsuja: {sender.first_name} {sender.last_name}<br/>
          {sender.phone}<br/>
          {sender.email}
        </div>}
      </Typography>
      
      <Typography variant="body1" className={classes.error}>
        {error ? error : ''}
      </Typography>
      
      <TextInput value={data.first_name.value}
        onChange={props.onChangeData('first_name')}
        required={data.first_name.required}
        fullWidth={true}
        error={data.first_name.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.first_name')}
      />

      <TextInput value={data.last_name.value}
        onChange={props.onChangeData('last_name')}
        required={data.last_name.required}
        fullWidth={true}
        error={data.last_name.error}
        margin="dense"
        onKeyPress={handleKeyPress}
        label={t('auth.last_name')}
      />
      <TextInput
        error={data.dateOfBirth.error}
        label={t('auth.date_of_birth')}
        fullWidth={true}
        margin="dense"
        max={data.dateOfBirth.max}
        min={data.dateOfBirth.min}
        name={t('auth.date_of_birth')}
        onChange={props.onChangeData('dateOfBirth')}
        onKeyPress={handleKeyPress}
        required={data.dateOfBirth.required}
        type="date"
        value={data.dateOfBirth.value}
      />
      {data.dateOfBirth.error &&
        <Typography variant="caption" className={classes.error}>
          {t('auth.date_of_Birth_requirements')}
        </Typography>
      }
      
      <TextInput
        error={data.taxNumber.error}
        fullWidth={true}
        inputProps={{
          maxLength: '12'
        }}
        label={t('auth.tax_number')}
        margin="dense"
        onBlur={props.onBlurTaxNumber}
        onChange={props.onChangeData('taxNumber')}
        onKeyPress={handleKeyPress}
        required={data.taxNumber.required}
        value={data.taxNumber.value}
      />
      {data.taxNumber.error &&
        <Typography variant="caption" className={classes.error}>
          {data.taxNumber.errorMessage ?
            data.taxNumber.errorMessage
          : t('auth.tax_number_invalid')}
        </Typography>
      }

      <TextInput
        error={data.phone.error}
        fullWidth
        label={t('auth.phone_number')}
        margin="dense"
        name="username"
        onChange={(v,c,e,fv) => props.onChangeData('phone')(fv)}
        onKeyPress={handleKeyPress}
        required={data.phone.required}
        type="phone"
        value={data.phone.value}
      />

      <hr className={classes.hr} />

      <div className={classes.box}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>{t('auth.company_country')}</FormLabel>
          <RadioGroup
            row
            ari-label='businessIdCountry'
            name='businessIdCountry'
            defaultValue={'finland'}
            value={businessIdCountry}
            onChange={(e)=>handleBusinessIdCountryRadioChange(e)}
          >
            <FormControlLabel 
              value='finland'
              control={<Radio color='primary' />}
              label={t('auth.finland')}
              labelPlacement='end'
            />
            <FormControlLabel 
              value='other'
              control={<Radio color='primary' />}
              label={t('auth.other')}
              labelPlacement='end'
            />
          </RadioGroup>
        </FormControl>
        <TextInput
          fullWidth={true}
          error={data.business_id.error}
          label={t('auth.business_id')}
          margin="dense"
          onChange={props.onChangeBusinessId}
          onKeyPress={handleKeyPress}
          readOnly={false}
          required={data.business_id.required}
          value={data.business_id.value}
        />
        <TextInput
          error={data.name.error}
          fullWidth={true}
          label={t('auth.company_name')}
          margin="dense"
          onChange={props.onChangeData('name')}
          onKeyPress={handleKeyPress}
          readOnly={data.prefill.value}
          required={data.name.required}
          value={data.name.value}
          disabled={businessIdCountry === "finland"}
        />
        <Button
          disabled={companyInfoOk}
          className={classes.employerButton}
          fullWidth
          onClick={()=>{eBtnSet(!eBtn)}}
          variant="contained" color="primary"
        >
          <span>{t('auth.employer')}</span>
          {showCompany?<DownIcon/>:<RightIcon/>}
        </Button>

        {showCompany &&


          <div>
            <Typography>
              {t('auth.employer')}
            </Typography>

            {[
              ["street1", t("contact.street_address")],
              ["street2", t("contact.street_address_row2")],
              //["co", t("contact.co")],
              ["postcode", t("contact.postcode")],
              ["city", t("contact.city")],
              ["country", t("contact.country")],
              ["explanation", t("contact.explanation")]
            ].map(renderInputs(data.prefill.value))}
        
            <br/>
        
            <Typography>
              {t('auth.contact_person')}
            </Typography>

            {[
              ["contact_first_name", t("contact.first_name")],
              ["contact_last_name", t("contact.last_name")],
              ["contact_street1", t("contact.street_address")],
              ["contact_street2", t("contact.street_address_row2")],
              //["contact_co", t("contact.co")],
              ["contact_postcode", t("contact.postcode")],
              ["contact_city", t("contact.city")],
              ["contact_country", t("contact.country")],
              ["contact_email", t("contact.email")],
              ["contact_phone", t("contact.phone")],

            ].map(renderInputs(data.prefill.value))}
             {/* <Typography>
              {t('auth.boss')}
            </Typography>
          <input
            //checked={isChecked}
            //className={classes.input}
            //id={id}
            name={'POMO'}
            onChange={handleBossCheckbox}
            value={data.business_id}
            //ref={inputEl}
            type="checkbox"
          /> */}
          </div>
          	
        }
      </div>

      <hr className={classes.hr} />

      <TextInput value={data.password.value}
        onChange={props.onChangeData('password')}
        required={data.password.required}
        error={data.password.error}
        label={t('auth.password')}
        type="password"
        fullWidth={true}
        helpertext={passwordHelper(data.password.value,data.password.value)}
        onKeyPress={handleKeyPress}
        margin="dense"
      />

      <Typography variant="caption">
        {t('auth.password_requirements')}
      </Typography>
      
      <TextInput value={data.password2.value}
        onChange={props.onChangeData('password2')}
        required={data.password2.required}
        error={!props.isValid(data.password2)}
        label={t('auth.password2')}
        type="password"
        fullWidth={true}
        helpertext={password2Helper(data.password.value,data.password2.value)}
        onKeyPress={handleKeyPress}
        margin="dense"
      />

      <div className={classes.termsContainer}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                name="termsAccepted"
                color="primary"
              />
            }
            label={t('info.i_accept_terms')}
          />
        </FormGroup>
        {/* <ButtonBase
          className={classes.termsButton}
          onClick={() => setTermsDialogOpen(true)}
        >
          {t('info.terms_of_service')} <OpenInNewIcon className={classes.termsButtonIcon} />
        </ButtonBase> */}
        <ButtonBase
          className={classes.termsButton}
          onClick={() => setPrivacyDialogOpen(true)}
        >
          {t('info.privacy_policy')} <OpenInNewIcon className={classes.termsButtonIcon} />
        </ButtonBase>
      </div>

      <Button
        className={classes.button}
        disabled={(!submitEnabled || !termsAccepted)}
        fullWidth
        onClick={register}
        variant="contained" color="primary"
      >
        {t('auth.register')}
        {loading && <Loading className={classes.loading} />}
      </Button>

      <TermsDialog
        type={"privacy"}
        open={privacyDialogOpen}
        onClose={() => setPrivacyDialogOpen(false)}
      />

    </form>
  )
}

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  isPhoneAccount: PropTypes.bool.isRequired,
	isValid:PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onBlurTaxNumber: PropTypes.func.isRequired,
  onChangeBusinessId: PropTypes.func.isRequired,
  onChangeData: PropTypes.func.isRequired,
  passwordHelper: PropTypes.func.isRequired,
  password2Helper: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool.isRequired,
  togglePhoneAccount: PropTypes.func.isRequired,
  businessIdCountry: PropTypes.string.isRequired,
  handleBusinessIdCountryRadioChange: PropTypes.func.isRequired,
  //handleBossCheckboxValue:PropTypes.string,
  handleBossCheckbox:PropTypes.func
};

export default withStyles(styles)(RegistrationForm)
