import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/Button/Button';

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(1 / 2.5),
    marginBottom: theme.spacing(1 / 2.25),
  },
  registerButton: {
    marginTop: theme.spacing(1 / 2.5),
    marginBottom: theme.spacing(1 / 2.25),
    width: "100%",
    height: "5rem"
  },
});

function Navigate(props) {
  const { classes, login, register, registerResident } = props;
  const { t } = useTranslation('common');

  return (
    <div>
      <Typography variant="h5" className={classes.header}>
        {t('scene.product_intro')}
      </Typography>
      <Button
        className={classes.button}
        onClick={login}
        data-testid="login"
        fullWidth
        variant="contained" color="secondary"
      >
        {t('auth.login')}
      </Button>
      <Button
        className={classes.registerButton}
        onClick={register}
        data-testid="login"
        variant="contained" color="primary"
        // style={{ marginRight: "2.5%" }}
      >
        {t('auth.register')}
      </Button>
      {/* <Button
        className={classes.registerButton}
        onClick={registerResident}
        data-testid="login"
        variant="contained" color="primary"
        style={{ marginLeft: "2.5%" }}
      >
        {t('auth.register_resident')}
      </Button> */}
    </div>
  )
}

Navigate.propTypes = {
  classes: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default withStyles(styles)(Navigate)