import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRoute, ROUTE } from '../../store/actions/router';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import LoginLogic from './LoginLogic';
import LoginNavigate from './components/LoginNavigate/LoginNavigate';
import LayoutSimpleGrid from 'components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';

const styles = theme => ({
  login: {
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  navigate: {
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
});

class Login extends Component {
  render() {
    const { classes, setHomeView, setResetPasswordView } = this.props;
    return (
      <LayoutSimpleGrid>
        <Grid className={classes.login}>
          <LoginLogic />
        </Grid>
        <Grid className={classes.navigate}>
          <LoginNavigate
            home={setHomeView}
            resetPassword={setResetPasswordView}
          />
        </Grid>
      </LayoutSimpleGrid>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  setHomeView: PropTypes.func.isRequired,
  setResetPasswordView: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setHomeView: () => dispatch(setRoute(ROUTE.HOME)),
    setResetPasswordView: () => dispatch(setRoute(ROUTE.RESET_PASSWORD)),
  }
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Login));
export { Login };