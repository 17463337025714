import React, { useState } from "react";
import "amazon-cognito-js";
import AWS from "aws-sdk";
import aws4 from "aws4";
import Config from "../../Config";
import PropTypes from "prop-types";
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import LoginForm from "./components/LoginForm/LoginForm";

import { setEmail, setPhone } from "../../store/actions/user";
import { setRoute, ROUTE } from "../../store/actions/router";

const DOMAIN_WHITELIST = [
  /^https:\/\/admin\.lixani\.com(\/.*$|$)/,
  /^https:\/\/my\.lixani\.com(\/.*$|$)/,
  /^https:\/\/work\.lixani\.com(\/.*$|$)/,
  /^https:\/\/beta\.admin\.lixani\.com(\/.*$|$)/,
  /^https:\/\/beta\.my\.lixani\.com(\/.*$|$)/,
  /^https:\/\/beta\.work\.lixani\.com(\/.*$|$)/,
];

const initLoginData = {
  username: "",
  password: "",
};

function Login(props) {
  const { t, i18n } = useTranslation("common");
  const [loginData, setLoginData] = useState({ ...initLoginData });
  const [loading, setLoading] = useState(false);
  const [stateError, setError] = useState(null);

  /**
   * 1) Authenticate User (User Pool) credentials, get id token
   * 2) Exchange id token for temporary IAM Identity (Federated Identity)
   * 3) Synchronize User details and Identity with database using GraphQL
   * 4) Store auth details in cookies and return to callback URL.
   * 5) Apps use SSO API to retrieve auth details from cookies.
   */
  const handleLogin = async () => {
    setError(null);
    setLoading(true);

    const { setEmail, setPhone, setVerificationView } = props;

    const authenticationDetails = new AuthenticationDetails({
      Username: loginData.username,
      Password: loginData.password,
    });

    const userData = {
      Username: loginData.username.toLowerCase(),
      Pool: new CognitoUserPool({
        UserPoolId: Config.COGNITO_USER_POOL_ID,
        ClientId: Config.COGNITO_CLIENT_ID,
      }),
    };

    const cognitoUser = new CognitoUser(userData);
    let authentication = null;
    try {
      authentication = await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onFailure: (error) => reject(error),
          onSuccess: (result) => resolve({ result: result }),
          newPasswordRequired: (userAttributes) =>
            resolve({ passwordRequired: { userAttributes: userAttributes } }),
        });
      });
    } catch (error) {
      authEnded();
      console.error("User authentication error", JSON.stringify(error));
      if (error.code === "UserNotConfirmedException") {
        if (userData.Username.includes("@")) {
          setEmail(userData.Username);
        } else {
          setPhone(userData.Username.replace(/[^a-zA-Z0-9+ ]/g, ""));
        }
        setVerificationView();
      } else {
        if (error.code === "NotAuthorizedException") {
          setError(t("error.incorrect_username_or_password"));
        } else if (error.code === "UserNotFoundException") {
          setError(t("error.incorrect_username_or_password"));
        } else {
          setError(error.message);
        }
      }
      return;
    }

    if (authentication.passwordRequired) {
      authEnded();
      //const userAttributes = authentication.passwordRequired.userAttributes;
      //delete userAttributes.email_verified;
      //delete userAttributes.phone_number_verified;
      //userAttributes.given_name = "First";
      //userAttributes.family_name = "Family";
      //cognitoUser.completeNewPasswordChallenge("xxx", userAttributes, this);
      return;
    }

    //-- Add locale attribute
    let locale = i18n.language;
    if (!locale) {
      locale = navigator.language || navigator.userLanguage;
    }
    locale = locale.split("-")[0];

    let localeAttrList = [];
    localeAttrList.push(
      new CognitoUserAttribute({ Name: "locale", Value: locale })
    );

    cognitoUser.updateAttributes(localeAttrList, (err, result) => {
      if (err) {
        console.log(err);
      }
      if (result) {
        console.log(result);
      }
    });

    const result = authentication.result;
    console.log("UserPool login result", result);
    const idToken = result.getIdToken().getJwtToken();
    const refreshToken = result.getRefreshToken().getToken();
    refreshIdentityCredentials(idToken);

    try {
      await new Promise((resolve, reject) => {
        AWS.config.credentials.refresh((error) => {
          error ? reject(error) : resolve();
        });
      });
    } catch (error) {
      authEnded();
      console.error("Error logging to Federated Identities", error);
      return;
    }

    console.log("Federated Identities login", AWS.config.credentials);
    const accessKeyId = AWS.config.credentials.accessKeyId;
    const secretAccessKey = AWS.config.credentials.secretAccessKey;
    const sessionToken = AWS.config.credentials.sessionToken;
    const keyExpiration = AWS.config.credentials.expireTime.getTime() / 1000;
    console.log(
      "accessKeyId",
      accessKeyId,
      "secretAccessKey",
      secretAccessKey,
      "sessionToken",
      sessionToken,
      "keyExpiration",
      keyExpiration
    );

    let data = null;
    try {
      data = await graphqlSyncUser(
        accessKeyId,
        secretAccessKey,
        sessionToken,
        idToken
      );
    } catch (error) {
      authEnded();
      console.error("UpdateUserMutation error", error);
      return;
    }

    console.log("UpdateUserMutation data", data);
    setAuthCookies(
      accessKeyId,
      secretAccessKey,
      sessionToken,
      keyExpiration,
      userData.Username,
      refreshToken
    );
    returnToCallbackURL();
  };

  const refreshIdentityCredentials = (id_token) => {
    const credentialParams = {
      IdentityPoolId: Config.COGNITO_IDENTITY_POOL_ID,
      Logins: {
        [Config.COGNITO_LOGIN_PROVIDER]: id_token,
      },
    };

    AWS.config.region = Config.AWS_REGION;
    let credentials = new AWS.CognitoIdentityCredentials(credentialParams);
    credentials.clearCachedId();
    credentials = new AWS.CognitoIdentityCredentials(credentialParams);
    AWS.config.credentials = credentials;
  };

  const graphqlSyncUser = async (
    accessKeyId,
    secretAccessKey,
    sessionToken,
    idToken
  ) => {
    const body = {
      operationName: "SyncUser",
      query: `
        mutation SyncUser($idToken: String!) {
            syncUser(input: {
              idToken: $idToken
            }) {
              id
            }
        }`,
      variables: { idToken },
    };

    const url = new URL(Config.GRAPHQL_ENDPOINT);
    const request = {
      host: url.host,
      path: url.pathname,
      method: "POST",
      service: "appsync",
      body: JSON.stringify(body),
    };
    const signedRequest = aws4.sign(request, {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });
    const resp = await fetch(url.href, signedRequest);
    console.log("syncUser response", JSON.stringify((await resp.json()).data));
  };

  /**
   * Set cookies used in account-sso-api lambda
   */
  const setAuthCookies = (
    accessKeyId,
    secretAccessKey,
    sessionToken,
    keyExpiration,
    username,
    refreshToken
  ) => {
    const expiry = new Date();
    expiry.setFullYear(expiry.getFullYear() + 1);
    const secureAttr = `;path=/api/; SameSite=None; Secure;`; // secure;
    const expiresAttr = `expires=${expiry.toUTCString()};`;

    document.cookie =
      "access_key = " +
      encodeURIComponent(
        JSON.stringify({
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
          keyExpiration: keyExpiration,
        })
      ) +
      secureAttr;
    document.cookie =
      "username = " + encodeURIComponent(username) + secureAttr + expiresAttr;
    document.cookie =
      "refresh_token = " +
      encodeURIComponent(refreshToken) +
      secureAttr +
      expiresAttr;
  };

  const authEnded = () => {
    setLoading(false);
  };

  /**
   * Send browser back to callback URL
   */
  const returnToCallbackURL = () => {
    const callback = new URL(window.location.href).searchParams.get("callback");
    const data = new URL(window.location.href).searchParams.get("data");

    if (
      callback &&
      (DOMAIN_WHITELIST.some((x) => callback.match(x)) ||
        Config.ALWAYS_CALLBACK === "true")
    ) {
      window.location = callback;
    } else {
      window.location = Config.MY_LIXANI_URL += data ? "?data=" + data : "";
    }
  };

  const handleLoginChange = (name) => (event) => {
    try {
      let value = event.target.value;

      if (name === "username")
        value = value.replace(/ /g, "").replace(/[^a-zA-Z0-9+ ]/g, "");

      setLoginData({ ...loginData, [name]: value });
    } catch (error) {
      let value = event;

      if (name === "username")
        value = value.replace(/ /g, "").replace(/[^a-zA-Z0-9+ ]/g, "");

      setLoginData({ ...loginData, [name]: value });
    }
  };

  const formValid = () => Object.entries(loginData).every((d) => d[1]);

  return (
    <LoginForm
      data={loginData}
      dataChanged={handleLoginChange}
      onLogin={handleLogin}
      submitEnabled={formValid()}
      setRegisterView={props.setRegisterView}
      setResetPasswordView={props.setResetPasswordView}
      error={stateError}
      loading={loading}
    />
  );
}

Login.propTypes = {
  setRegisterView: PropTypes.func.isRequired,
  setResetPasswordView: PropTypes.func.isRequired,
  setVerificationView: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRegisterView: () => dispatch(setRoute(ROUTE.REGISTRATION)),
    setResetPasswordView: () => dispatch(setRoute(ROUTE.RESET_PASSWORD)),
    setVerificationView: () => dispatch(setRoute(ROUTE.VERIFICATION)),
    setEmail: (email) => dispatch(setEmail(email)),
    setPhone: (phone) => dispatch(setPhone(phone)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
export { Login };
